<template>
  <div class="dispensers-table rounded-lg shadow-lg">
    <div class="dispensers-table__header">
      <el-input size="small" placeholder="Dispenser number..." v-model="qMiniId"></el-input>
      <el-date-picker
          class="ml-4"
          size="small"
          v-model="qRange"
          @change="changeRange"
          type="daterange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
      >
      </el-date-picker>
    </div>
    <div class="dispensers-table__body">
      <el-table
          v-loading="loading"
          :data="filtered"
          stripe
          style="width: 100%">
        <el-table-column
            prop="mini_id"
            label="Stantion Number"
        >
          <template #default="scope">
            <span class="text-lg">{{scope.row.mini_id}}</span>
          </template>
        </el-table-column>
        <el-table-column
            sortable
            align="center"
            prop="status"
            label="Status">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'online'" type="success" size="medium">Online</el-tag>
            <el-tag v-else type="info" size="medium">Offline</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="last_sync_time"
            label="Last Activity At">
          <template #default="scope">
            {{ (new Date(scope.row.last_sync_time)).toLocaleDateString() }}
          </template>
        </el-table-column>
        <el-table-column
            align="right"
            sortable
            prop="free_slots"
            label="Free Slots">
          <template #default="scope">
            {{ scope.row.free_slots.toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column
            align="right"
            sortable
            prop="rents_count"
            label="Rents Count">
          <template #default="scope">
            {{ scope.row.rents_count.toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column
            align="right"
            sortable
            prop="range_royalty"
            label="Range Profit Amount">
          <template #default="scope">
            <small class="text-gray-600">$</small> {{ scope.row.range_royalty.toLocaleString() }}
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--            align="center"-->
        <!--            label="Actions"-->
        <!--        >-->
        <!--          <template #default>-->
        <!--            <el-button type="danger" size="mini" round>Report</el-button>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DispenserModel from "@/models/DispenserModel";

export default defineComponent({
  name: 'DispensersTable',
  props: {
    dispensers: {
      type: Array as () => Array<DispenserModel>,
      required: true
    },
    loading: {
      type: Boolean as () => boolean,
      required: true
    },
  },
  data() {
    return {
      qMiniId: '' as string,
      qRange: [] as Array<string>,
    }
  },

  computed: {
    filtered(): Array<DispenserModel> {
      return this.dispensers.filter(data => !this.qMiniId.trim() || data.mini_id.toString().toLowerCase().includes(this.qMiniId.trim().toLowerCase()))
    }
  },
  methods: {
    changeRange(value: Array<string>): void {
      this.$emit('changeRange', value)
    }
  }
});
</script>

<style scoped lang="scss">
.dispensers-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    width: 100%;
    padding: 0.5rem 0.5rem;
  }

  &__body {
    display: flex;
    width: 100%;
    margin-top: 0.25rem;
  }
}
</style>
