import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import store from "@/store/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    },
    {
        path: '/',
        name: 'panel',
        component: Dashboard,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (!store.token) {
            next({name: 'login'})
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.auth === false)) {
        if (!store.token) {
            next()
        } else {
            next({name: 'panel'})
        }
    } else {
        next()
    }
});

export default router
