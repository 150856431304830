import { reactive } from "vue";
import User from "@/models/UserModel";

interface Store {
    user: User,
    token?: string,
}

const state = reactive({} as Store);

export default state;
