
import { defineComponent } from 'vue';
import DispensersTable from "@/components/DispensersTable/DispensersTable.vue"; // @ is an alias to /src
import DispenserModel from "@/models/DispenserModel";
import { AxiosResponse } from "axios";
import AnimatedNumber from "@/components/AnimatedNumber/AnimatedNumber.vue";
import Navbar from "@/components/Navbar/Navbar.vue";

interface Params {
  range_start?: string;
  range_end?: string;
}

interface Stats {
  total_dispensers?: number;
  total_rents?: number;
  total_profit?: number;
}

export default defineComponent({
  name: 'Dashboard',
  components: {
    Navbar,
    AnimatedNumber,
    DispensersTable,
  },
  data() {
    return {
      loading: false,
      dispensers: [] as Array<DispenserModel>,
      stats: {
        total_dispensers: 0,
        total_rents: 0,
        total_profit: 0,
      } as Stats,
      loadingStats: false,
    }
  },
  mounted() {
    this.getStats();
    this.getDispensers();
  },
  methods: {
    getStats(): void {
      this.loadingStats = true;
      this.$axios.get('/franchise/stats')
          .then((response: AxiosResponse) => {
            if (response.data.data) {
              this.stats = response.data.data
            }
          })
          .finally(() => this.loadingStats = false)
    },
    getDispensers(range?: Array<string>): void {
      let params: Params = {};

      if (range) {
        params.range_start = new Date(range[0]).toISOString().slice(0, 10);
        params.range_end = new Date(range[1]).toISOString().slice(0, 10);
      }

      this.loading = true;
      this.$axios.get('/franchise/battery-dispensers', {params})
          .then((response: AxiosResponse) => {
            if (response.data.data) {
              this.dispensers = response.data.data
            }

          })
          .finally(() => this.loading = false)
    },
    handleChangeRange(range: Array<string>) {
      this.getDispensers(range)
    }
  }
});
