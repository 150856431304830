
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      loading: false,
      form: {
        phone: '',
        password: ''
      },
      rules: {
        phone: [
          {required: true, message: 'Please input phone', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Please input password', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    submitForm(): void {
      (this.$refs.form as any).validate((valid: boolean) => {
        if (valid) {
          this.loading = true;

          this.$auth.login(this.form.phone, this.form.password).finally(() => {
            this.loading = false
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
});
