<template>
  <div v-if="user" class="navbar text-gray-600">
    <i class="el-icon-office-building mr-2"></i>
    <span>{{ user.franchise_title }}</span>
    <el-divider direction="vertical" class="mx-4"></el-divider>
    <i class="el-icon-user "></i>
    <span class="ml-2">{{ user.phone }}</span>
    <el-divider direction="vertical" class="mx-4"></el-divider>
    <el-button type="text" @click="logout">Logout</el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import store from "@/store/store";
import User from "@/models/UserModel";

export default defineComponent({
  name: "Navbar",
  computed: {
    user(): User {
      return store.user
    }
  },
  methods: {
    logout(): void {
      this.$auth.logout();
    }
  }
});
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  width: 100%;
}
</style>
