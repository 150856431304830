<template>
  <Navbar/>
  <div class="container dashboard">
    <div class="dashboard__header">
      <div class="dashboard__header__title">
        <h1>Dispensers</h1>
        <span class="text-gray-600">Track your stations and rents</span>
      </div>
      <div class="dashboard__header__stats" v-loading="loadingStats">
        <div class="flex flex-col">
          <span class="text-gray-600">Stations</span>
          <span class="text-gray-700"><i class="el-icon-set-up text-blue-600 text-2xl mr-2"></i><span class="text-3xl">
            <AnimatedNumber :number="stats.total_dispensers"/>
          </span></span>
        </div>
        <div class="flex flex-col">
          <span class="text-gray-600">Total rents</span>
          <span class=" text-gray-700"><i class="el-icon-takeaway-box text-indigo-600 text-2xl mr-2"></i><span
              class="text-3xl">
            <AnimatedNumber :number="stats.total_rents"/>
          </span></span>
        </div>
        <div class="flex flex-col">
          <span class="text-gray-600">Total profit</span>
          <span class=" text-gray-700"><i class="el-icon-money text-green-500 text-2xl mr-2"></i><span class="text-3xl">
           $ {{stats.total_profit.toLocaleString()}}
          </span></span>
        </div>
      </div>
    </div>
    <DispensersTable :dispensers="dispensers" :loading="loading" @change-range="handleChangeRange"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DispensersTable from "@/components/DispensersTable/DispensersTable.vue"; // @ is an alias to /src
import DispenserModel from "@/models/DispenserModel";
import { AxiosResponse } from "axios";
import AnimatedNumber from "@/components/AnimatedNumber/AnimatedNumber.vue";
import Navbar from "@/components/Navbar/Navbar.vue";

interface Params {
  range_start?: string;
  range_end?: string;
}

interface Stats {
  total_dispensers?: number;
  total_rents?: number;
  total_profit?: number;
}

export default defineComponent({
  name: 'Dashboard',
  components: {
    Navbar,
    AnimatedNumber,
    DispensersTable,
  },
  data() {
    return {
      loading: false,
      dispensers: [] as Array<DispenserModel>,
      stats: {
        total_dispensers: 0,
        total_rents: 0,
        total_profit: 0,
      } as Stats,
      loadingStats: false,
    }
  },
  mounted() {
    this.getStats();
    this.getDispensers();
  },
  methods: {
    getStats(): void {
      this.loadingStats = true;
      this.$axios.get('/franchise/stats')
          .then((response: AxiosResponse) => {
            if (response.data.data) {
              this.stats = response.data.data
            }
          })
          .finally(() => this.loadingStats = false)
    },
    getDispensers(range?: Array<string>): void {
      let params: Params = {};

      if (range) {
        params.range_start = new Date(range[0]).toISOString().slice(0, 10);
        params.range_end = new Date(range[1]).toISOString().slice(0, 10);
      }

      this.loading = true;
      this.$axios.get('/franchise/battery-dispensers', {params})
          .then((response: AxiosResponse) => {
            if (response.data.data) {
              this.dispensers = response.data.data
            }

          })
          .finally(() => this.loading = false)
    },
    handleChangeRange(range: Array<string>) {
      this.getDispensers(range)
    }
  }
});
</script>

<style lang="scss">
.dashboard {
  display: flex;
  flex-direction: column;

  &__header {
    @apply text-gray-700;

    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

    &__title {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 2.5rem;
        //padding-left: 2rem;
      }
    }

    &__stats {
      display: flex;
      align-items: center;

      div + div {
        margin-left: 3rem;
      }
    }

  }
}
</style>
