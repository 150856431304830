
import { defineComponent } from 'vue';
import store from "@/store/store";
import User from "@/models/UserModel";

export default defineComponent({
  name: "Navbar",
  computed: {
    user(): User {
      return store.user
    }
  },
  methods: {
    logout(): void {
      this.$auth.logout();
    }
  }
});
