import router from "../router";
import { App } from "vue";
import { AxiosResponse } from "axios";
import store from "@/store/store";


declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        $auth: {
            login: (phone: string, password: string) => Promise<void>,
            fetchUser: () => void,
            logout: () => void,
            setToken: () => void,
            unsetToken: () => void,
        },
    }
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$auth = {
            login(phone: string, password: string) {
                return new Promise((resolve, reject) => {
                    app.config.globalProperties.$axios
                        .post('/account/check-code', {
                            phone: phone,
                            reset_code: password,
                        })
                        .then((response: AxiosResponse) => {
                            if (response.data && response.data.access_token) {
                                this.setToken(response.data.access_token)
                                this.fetchUser()
                                router.push({name: 'panel'})
                            }

                            resolve(response)
                        })
                        .catch((error: AxiosResponse) => {
                            reject(error)
                        })
                });
            },
            fetchUser() {
                return new Promise((resolve, reject) => {
                    app.config.globalProperties.$axios.post('/account/me')
                        .then((response: AxiosResponse) => {

                            store.user = {
                                phone: response.data.data.phone,
                                franchise_title: response.data.data.franchise_title,
                            }

                            resolve(response)
                        })
                        .catch((error: AxiosResponse) => {
                            this.logout();
                            reject(error)
                        })
                });
            },
            logout(silent = false) {
                return new Promise((resolve, reject) => {
                    if (!silent) {
                        app.config.globalProperties.$axios.post('/account/logout')
                            .then((response: AxiosResponse) => {
                                resolve(response)
                            })
                            .catch((error: AxiosResponse) => {
                                reject(error)
                            })
                    }

                    this.unsetToken()
                    router.push({name: 'login'});
                })
            },
            getToken() {
                return localStorage.getItem('franchise-panel-jwt');
            },
            setToken(token: string) {
                store.token = token;
                localStorage.setItem('franchise-panel-jwt', token);
                app.config.globalProperties.$axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
            },
            unsetToken() {
                localStorage.removeItem('franchise-panel-jwt');
                store.token = undefined;
                app.config.globalProperties.$axios.defaults.headers.common['Authorization'] = null;
            }
        };

        //Востановление сессии
        const token = localStorage.getItem('franchise-panel-jwt');
        if (token) {
            app.config.globalProperties.$auth.setToken(token)
            app.config.globalProperties.$auth.fetchUser();
        }
    }
}
