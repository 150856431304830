<template>
  <span>{{ displayNumber.toLocaleString() }}</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnimatedNumber",
  props: {
    number: {type: Number as () => number, default: 0}
  },
  data() {
    return {
      displayNumber: 0 as number,
      interval: 0 as number,
    }
  },
  mounted() {
    this.displayNumber = this.number ? this.number : 0;
  },
  watch: {
    number: {
      immediate: true,
      handler() {
        clearInterval(this.interval);

        if (this.number === this.displayNumber) {
          return;
        }

        this.interval = window.setInterval(function (this: any) {
          if (this.displayNumber !== this.number) {
            let change = (this.number - this.displayNumber) / 10;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            this.displayNumber = this.displayNumber + change;
          }
        }.bind(this), 60);
      },
    },
  }
});
</script>

<style scoped>

</style>
